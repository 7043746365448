/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

.pip-summary {
  .name-area {
    width: 28%;
    .chart-header-title {
      font-weight: bold;
      align-self: flex-end;
      color: white;
      width: 100%;
      padding: 4px 8px;
      margin: 0 2px;
    }
  }

  .manager-area {
    font-size: 10px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    padding: 2px;
    width: 38%;
  }

  .manager-area-summary {
    padding-left: 8px;
    font-size: 8px;
  }

  .pip-summary-charts .chart-area,
  .region-chart-area {
    background: #e4f0f0 !important;
  }

  ::-webkit-scrollbar {
    height: 0;
  }
}
