/*
 * Copyright (C) 2023 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

$dn-background: #cddfdb;
$line-orange: #fb8e2c;
// $line-green: #4CC63D;
$line-green: #5cd68b;
$line-maroon: #c4a9c1;
$line-blue: #3fa4e8;
$dn-primary: #01b8aa;

.daily-number-report {
  position: relative;
  min-height: 100vh;
  background: $dn-background;
  width: 100%;

  & * {
    font-size: 0.7rem;
    @media  (min-width: 768px) and (max-width:1280px) {
      font-size: 0.6rem;
    }
    @media  (max-width: 768px) {
      font-size: 0.5rem;
    }
    
    // @media  (max-width: 640px) and (min-width: 768px){
    //   font-size: 0.6rem;
    // }

    // @media (min-width: 2140px) and (max-width: 2400px) {
    //   font-size: 0.8rem;
    // }

    // @media (min-width: 1800px) and (max-width: 2140px) {
    //   font-size: 0.7rem;
    // }

    // @media (min-width: 1600px) and (max-width: 1800px) {
    //   font-size: 0.63rem;
    // }

    // @media (min-width: 1400px) and (max-width: 1600px) {
    //   font-size: 0.6rem;
    // }

    // @media (min-width: 1200px) and (max-width: 1400px) {
    //   font-size: 0.45rem;
    // }

    // @media (min-width: 900px) and (max-width: 1200px) {
    //   font-size: 0.4rem;
    // }

    // @media (min-width: 700px) and (max-width: 900px) {
    //   font-size: 0.25rem;
    // }
  }

  .main-section {
    height: 100%;
    overflow: auto;
  }

  .dn-sidebar-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: sticky;
    top: 0;
    width: 8vw;
    min-width: 150px;
    // padding: 16px 8px;s
    height: 100vh;
    @media (max-width: 640px) {
      width: 120px;
      min-width: 120px;
      padding: 14px 6px;
    }

    .sidebar-item {
      cursor: pointer;
      width: 100%;
      text-align: center;
      background: rgba(255, 255, 255, 9.12);
      padding: 4px;
      border: 1px solid rgba(0, 0, 0, 0.5);
      border-radius: 300px;
      margin-bottom: 4px;
      transition: 0.3s;
      font-size: 11px;

      &:hover {
        filter: brightness(0.8);
      }

      &.active {
        background: coral;
        color: white;
      }
    }
  }

  .dn-header {
    display: flex;
    padding-right: max(10vw, 150px);
    // padding-right:0.5rem;
    position: sticky;
    padding-bottom: 8px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.4);
    top: 0;
    z-index: 999;
    background: $dn-background;
    @media (max-width: 1280px) {
      position:static;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap:8px;
      padding-right: 0;
    }

    // .first-col,
    // .third-col {
    //   width: 20%;
    // }

    .third-col {
      padding-bottom: 8px;

      .year-selector-box {
        padding: 8px;
        border: 1px solid rgba(0, 0, 0, 0.5);
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        max-width: 100%;
        @media (min-width: 1000px) and (max-width: 2800px) {
          font-size: 0.9rem;
          min-width: 120px;
        }

        label {
          padding-bottom: 4px;
        }

        select {
          background: transparent;
          padding: 4px 8px;
          border-radius: 4px;
          flex: 1;
          max-width: 100%;
        }
      }
    }

    // .second-col {
    //   width: 60%;
    // }

    .dn-title-area {
      padding: 0 10vw;
    }

    .dn-filter-button {
      background: transparent;
      height: 24px;
      font-size: 11px;
      border: 1px solid black;
      margin-left: 4px;
    }

    .dn-title {
      padding: 8px 16px;
      width: 500px;
      font-size: 16px;
      margin: 8px auto;
      font-weight: bold;
      @media (max-width: 768px) {
        padding: 2px 
        ;
      }
    }
  }

  .daily-number-region-table {
    // margin: 16px 0 4px;
    // width: 100%;
    // display: flex;
    .table-area {
      flex: 1;
      border: 1px solid rgba(0, 0, 0, 0.9);
      max-height: 65vh;
      overflow: auto;

      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background: rgb(152, 152, 152);
      }

      .dnr-row {
        display: flex;
        padding: 0px 4px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        cursor: pointer;
        min-width: 150px;

        &:hover {
          background: rgba(0, 0, 0, 0.12);
        }

        &.today {
          background: $line-green;
        }

        &.blue {
          background: $line-blue !important;
        }

        &.table-header {
          background: $dn-background;
          position: sticky;
          top: 0;
          align-items: center;
          border-bottom: 1px solid rgba(0, 0, 0, 0.9);
        }

        .dnr-col {
          padding: 4px 0;
          flex: 1;
          text-align: center;
          min-width: 100px;
          &.today {
            background: $line-green;
          }
  
          &.blue {
            background: $line-blue !important;
          }
      
          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(3) {
            width: 100px;
            flex: unset;
            word-break: break-word;
          }

          &:hover {
            background: rgba(0, 0, 0, 0.12);
          }
        }
      }
    }

    .filter-area {
      width: 8vw;
      padding: 0 8px;
      min-width: 150px;
      @media (max-width:768px){
       width:fit;
  
      }

      .filter-box {
        border: 1px solid rgba(0, 0, 0, 0.9);
        padding: 4px;
      

        .filter-day {
          padding: 4px 0;
        }
      }
    }
  }

  .daily-number-area-chart {
    margin: 8px 0;

    .filter-area {
      width: 8vw;
      min-width: 150px;
      @media (max-width:767px){
        width:0px;
       min-width: 0px;
      }
    }
  }

  .daily-number-area-chart,
  .daily-number-region-chart {
    .chart-area {
      border: 1px solid rgba(0, 0, 0, 0.9);
      height: 400px;
    }
  }

  .daily-number-branch-table {
    display: flex;

    .table-area {
      flex: 1;

      .dnb-section {
        margin: 4px 0 16px;
      }

      .dnb-header-title {
        background: $line-blue;
        padding: 4px;
        //font-weight: bold;
        font-size: 1.2em;
      }

      .dnb-row {
        display: flex;
        border: 1px solid transparent;
        padding: 2px 4px;
        //border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        cursor: pointer;
        //
        //&:hover {
        //  background: rgba(0, 0, 0, 0.12);
        //}

        &.dnb-header {
          padding: 8px 4px;
          border: 1px solid rgba(0, 0, 0, 0.9);
        }

        .dnb-col {
          flex: 1;
          // width: 12.5%;
          min-width: 80px;
        
          //&:hover {
          //  background: rgba(0, 0, 0, 0.12);
          //}
          &:not(&:first-child) {
            text-align: center;
          }

          &:first-child {
            width: 100px;
            flex: unset;
            word-break: break-word;
          }

          //&:first-child{
          //&:first-child{
          //  flex:3;
          //}
        }
      }
    }

    .filter-area {
      width: 8vw;
      min-width: 150px;
      @media (max-width:767px){
        width:0px;
       min-width: 0px;
      }
    }
  }
}

.year-comparison-title {
  display: flex;
  align-items: center;

  label {
    font-size: 1.2em;
  }

  .comparison-select-box {
    margin-left: 8px;

    select {
      border: 1px solid rgba(0, 0, 0, 0.6);
      background: transparent;
      padding: 4px 8px;
      border-radius: 4px;
      min-width: 150px;
      width: 100%;
    }
  }
}
