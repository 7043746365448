/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

.screen.financial-report {
  $financial-primary: #01b8aa;
  $financial-secondary: #f5d33f;
  $financial-primary-light: #cddfdb;

  width: 100vw;
  padding: 8px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  .bg-primary {
    background-color: $financial-primary;
  }

  .bg-primary-light {
    background-color: $financial-primary-light;
  }

  .bg-secondary {
    background-color: $financial-secondary;
  }

  .back-button {
    padding: 8px 12px;
    cursor: pointer;
    background: rgba(0, 0, 0, 0.9);
    color: white;
    border: none;
    border-radius: 4px;
    margin: 2px 8px;
  }

  .home-btn-financial {
    color: $financial-primary;
    cursor: pointer;
    font-size: 36px;
    transition: 0.3s;

    &:hover {
      filter: brightness(0.8);
    }
  }

  .financial-side-options {
    display: flex;
    justify-content: flex-end;
    width: 35%;

    .refresh-button-area {
      .refresh-button {
        padding: 8px 12px;
        cursor: pointer;
        background: $financial-primary;
        color: white;
        border: none;
        border-radius: 4px;
        margin: 0 2px;

        &.dark {
          background: rgba(0, 0, 0, 0.8);
        }
      }
    }
  }

  header {
    .financial-header {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      div {
        margin: 2px;
        padding: 4px 16px;
        font-size: 16px;
      }

      .intermountain {
        min-width: 250px;
      }

      .selection {
        min-width: 200px;
      }

      .date-title {
        min-width: 300px;
      }
    }

    .financial-year-select,
    .financial-month-select {
      float: right;
      margin-left: 2px;
      border: 1px solid rgba(0, 0, 0, 0.5);
      padding: 8px;
      box-sizing: border-box;

      select,
      input {
        min-width: 200px;
        padding: 2px 0;
        margin-top: 8px;
        border: none;
      }
    }
  }

  section.financial-table {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(0, 0, 0, 0.12);
    margin: 4px 0;
    overflow: auto;

    .table-col.block {
      display: block !important;

      & > div {
        white-space: break-spaces;
      }
    }

    .financial-table-headers,
    .financial-table-row {
      display: flex;

      .table-col:first-child {
        min-width: 300px;
      }

      .table-col {
        border-top: 1px solid rgba(0, 0, 0, 0.12);
        box-sizing: border-box;
        padding: 4px 8px;
        flex: 1;
        min-width: 80px;
      }
    }

    // .financial-table-row:nth-child(1) {
    //   .table-col {
    //     &:nth-last-child(1) {
    //       border-top: none;
    //     }
    //   }
    // }

    .financial-table-headers {
      padding: 4px 0;
      position: sticky;
      top: 0;
      z-index: 99;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);

      .separate-col{
        border-bottom:1px solid rgba(0, 0, 0, 0.12);
      }
    }
  }

  .branch-buttons {
    width: 100%;

    .branch-button {
      width: 5%;
      min-height: 22px;
      cursor: pointer;
      border: 1px solid rgba(0, 0, 0, 0.12);
      max-width: 200px;

      &.all {
        background: #80dcd5;
      }

      &.primary {
        background: #99e3dd;
      }

      &.secondary {
        background: #b3eae6;
      }

      &.tertiary {
        background: #ccf1ee;
      }

      &.quarternary {
        background: #e6f8f7;
      }

      &.quinary {
        background: #fff;
      }

      &.active {
        background: $financial-primary !important;
        color: white;
      }

      &.selected {
        filter: brightness(0.85);
        &.active {
          filter: brightness(1);
        }
      }
    }
  }
}

// .financial-table-data {
//   margin-top:2px;
// }

.screen.financial-report,
.screen.financial-report button {
  font-size: 0.7rem;
  // @media (min-width: 2400px) and (max-width: 2800px) {
  //   font-size: 0.9rem;
  // }

  // @media (min-width: 2140px) and (max-width: 2400px) {
  //   font-size: 0.8rem;
  // }

  // @media (min-width: 1800px) and (max-width: 2140px) {
  //   font-size: 0.7rem;
  // }

  // @media (min-width: 1600px) and (max-width: 1800px) {
  //   font-size: 0.63rem;
  // }

  // @media (min-width: 1400px) and (max-width: 1600px) {
  //   font-size: 0.6rem;
  // }

  // @media (min-width: 1200px) and (max-width: 1400px) {
  //   font-size: 0.45rem;
  // }

  // @media (min-width: 900px) and (max-width: 1200px) {
  //   font-size: 0.4rem;
  // }

  // @media (min-width: 700px) and (max-width: 900px) {
  //   font-size: 0.25rem;
  // }
}

.data-loading-section {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
}

.bg-gray {
  background: rgba(0, 0, 0, 0.08);
}

.financial-table::-webkit-scrollbar {
  width: 3px;
}

.modal-content {
  ::-webkit-scrollbar {
    width: 1px;
  }
}

.branches-list::-webkit-scrollbar {
  width: 2px !important;
}
