/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

$ar-primary: #2689d7;


.ar-bg-primary {
  background-color: $ar-primary;
}


.dashboard.screen.ar-aging-report {
  display: flex;
  flex-direction: column;

  .main-content {
    flex: 1;
    padding: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .ar-table {
      flex: 1;
      width: 100%;
      overflow: auto;

      .ar-table-data {
        flex: 1;
      }

      .ar-table-col {
        width: 6.5%;
        padding: 4px;
        overflow: hidden;
        text-overflow: clip;
        min-width: 65px;

        &:first-child {
          width: 25%;
          min-width: 100px;


          &.is-summary {
            width: 57.5%;
          }
        }

        &:nth-last-child(1) {
          width: 3.5%;
          min-width: 50px;
        }
      }
    }
  }
}

.dashboard.screen.ar-aging-report {

  &, & select, & input, & button {
    font-size: 0.7rem;
    @media  (min-width: 768px) and (max-width:1280px) {
      font-size: 0.6rem;
    }
    @media  (max-width: 768px) {
      font-size: 0.5rem;
    }
    // @media (min-width: 2400px) and (max-width: 2800px) {
    //   font-size: .9rem;
    // }

    // @media (min-width: 2140px) and (max-width: 2400px) {
    //   font-size: .8rem;
    // }

    // @media (min-width: 1800px) and (max-width: 2140px) {
    //   font-size: .7rem;
    // }

    // @media (min-width: 1600px) and (max-width: 1800px) {
    //   font-size: .8rem;
    // }

    // @media (min-width: 1400px) and (max-width: 1600px) {
    //   font-size: .8rem;
    // }

    // @media (min-width: 1200px) and (max-width: 1400px) {
    //   font-size: 0.8rem;
    // }

    // @media (min-width: 900px) and (max-width: 1200px) {
    //   font-size: 0.4rem;
    // }

    // @media (min-width: 700px) and (max-width: 900px) {
    //   font-size: 0.25rem;
    // }

    // @media (max-width: 700px) {
    //   font-size: 0.3rem;
    // }
  }
}

.ar-aging-report {

  .ar-aging-header {
    color: white;
    text-align: center;
    padding: 8px 0;
    font-weight: bold;
   
  }

  .ar-filter-section {
//     height: 70px;
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     margin: 8px 0;
//     @media (max-width:1280px) and  (min-width:768px){
//       height: fit-content;
//       display:grid;
//       grid-template-columns: 50% 50%;
     
//     }
//     @media (max-width:768px){
//       height: fit-content;
//       display:grid;
//       grid-template-columns: 80% 20%;
     
//     }

    .ar-date-type-selects {
      .date-type-select {
        margin: 2px;
        border: 1px solid gray;
        background: white;
        border-radius: 30px;
        padding: 4px 8px;
        width: 100%;
        cursor: pointer;

        &.active {
          background: $ar-primary;
          color: white;
        }
      }
    }

    .ar-selects {
      // width: 35%;
      display: flex;
      justify-content: space-between;
      // @media (max-width:1280px) {
      //   width: 70%;
      // }

      .ar-select {
        padding: 8px;
        border: 1px solid rgba(0, 0, 0, 0.50);
        // width: calc(50% - 4px);
        display: flex;
        flex-direction: column;

        select, input {
          padding: 2px 0;
          margin-top: 8px;
          border: none;
        }
      }
    }

    .ar-date-type-selects {
      // width: 10%;
      padding: 0 16px;
    }

    .ar-date-range-buttons, .ar-column-types-buttons {
      display: flex;
      // width: 55%;
      align-items: center;
      // height: full;
      // @media (max-width:1280px) {
      //   width: 70%;
      // }

      .ar-date-range-button, .ar-column-types-button {
        border: 1px solid rgba(0, 0, 0, 0.50);
        height: 100%;
        // min-width: 10%;
        padding: 2px 18px;
        word-break: keep-all;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 4px;
        transition: .3s;
        @media (max-width:640px){
          padding :2px 2px;
        }

        &:hover {
          background: rgba(0, 0, 0, 0.12);
        }

        &.active {
          background: $ar-primary;
          color: white
        }
      }
    }
  }
}

.total-balance-box {
  max-height: 100%;
  min-height: 80%;
  border: 1px solid rgba(0, 0, 0, 0.3);
  display:flex;
  flex-direction: column;
  .total-balance-amount,
  .total-balance-label {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .total-balance-amount {
    width: 100%;
    flex:1;
    padding: 0 4px;

  }

  .total-balance-label {
    padding: 0 4px;
    width: 100%;
    background: $ar-primary;
    color: white;
  }
}


.ar-comment-section {
  position: fixed;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;

  .ar-comment-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.3);
  }

  .ar-export-dialog-card {
    background: white;
    border-radius: 4px;
    animation: fadeIn .3s ease-in-out;
    display: flex;
    padding: 8px;
    flex-direction: column;

    .ar-title {
      font-size: 16px;
      font-weight: bold;
      padding: 8px 8px;
      text-align: center;
    }

    .ar-export-dialog-content {
      button {
        color: white;
        padding: 8px 16px;
        border-radius: 4px;
        border: none;
        cursor: pointer;
        transition: .3s;
        margin: 8px;
        background: $ar-primary;

        &:nth-child(2) {
          background: black;
        }
      }
    }
  }

  .ar-export-dialog-card {
    position: relative;
  }

  .ar-comment-dialog-card {
    position: relative;
    min-width: 50vw;
    max-width: 60vw;
    max-height: 80vh;
    min-height: 40vh;
    background: white;
    border-radius: 4px;
    animation: fadeIn .3s ease-in-out;
    display: flex;
    flex-direction: column;

    .ar-title {
      font-size: 24px;
      font-weight: bold;
      padding: 16px;
      border-bottom: 1px solid rgba(0, 0, 0, .12);

      .branch-name {
        font-weight: bolder;
        color: $ar-primary;
      }
    }

    .ar-comment-dialog-content {
      flex: 1;
      //max-width: 100%;
      width: calc(60vw - 16px);
      padding: 16px;
      overflow-y: auto;
      overflow-x: hidden;

      .ar-comment {
        border: 1px solid rgba(0, 0, 0, .12);
        padding: 8px;
        margin: 8px 0;
        width: calc(60vw - 54px);
      }

      .ar-content-subject {
        font-size: 16px;
        margin-bottom: 8px;
      }
    }
  }
}

.export-data-section {
  button {
    background: $ar-primary;
    color: white;
    padding: 8px 16px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    transition: .3s;
  }
}
