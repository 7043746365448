/*!
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

*,
div {
  box-sizing: border-box !important;
}

.screen {
  height: 100vh;
  width: 100%;
}

.text-bold {
  font-weight: bold;
}

.one-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

//typography

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

//windows
.full-width {
  width: 100%;
}

.dropdown {
  position: relative;

  &:hover .dropdown-content {
    display: block;
  }

  .dropdown-content {
    padding: 4px;
    top: 100%;
    right: 0;
    color: #3e3e3e;
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    z-index: 1;

    .dropdown-content-item {
      padding: 8px;
      border-radius: 4px;
      transition: 0.3s;
      cursor: pointer;
      display: flex;
      align-items: center;

      label {
        transition: 0.3s;
        margin-left: 4px;
        margin-bottom: 4px;
      }

      .dropdown-content-icon {
        transition: 0.3s;
        color: rgba(0, 0, 0, 0.3);
      }

      &:hover {
        background: rgba(0, 0, 0, 0.12);
      }

      &.active {
        background: rgba(0, 0, 0, 0.8);
        color: white;

        .dropdown-content-icon {
          color: white;
        }
      }
    }
  }
}

.hidden {
  display: none;
}

.ar-table-row:nth-last-child(1) {
  margin-bottom: 4px !important;
}

.ar-table-row.sub-total {
  background: rgba(0, 0, 0, 0.12);
  font-weight: bolder;
}

.ar-table-row.grand-total {
  background: #2689d7;
  color: white;
  font-weight: bolder;
  padding: 6px 0;
}

.ar-date-reset-button {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;

  &:hover {
    background: rgba(0, 0, 0, 0.2);
  }

  &:active {
    transform: translateY(2px);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.alert-dialog {
  z-index: 10000;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;

  .alert-backdrop {
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  .alert-dialog-card {
    max-width: 60vw;
    position: relative;
    border-radius: 8px;
    background: white;
    padding: 8px;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
  }

  .buttons-area {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;

    button {
      padding: 8px 16px;
      border-radius: 8px;
      background: rgba(0, 0, 0, 0.8);
      color: white;
      border: none;
    }
  }
}

.fc .fc-button-primary {
  background-color: #3b608a !important;
  border-color: #fff !important;
}

#redeployment-date::placeholder {
  color: #000 !important;
}

.rdrDateDisplayWrapper {
  display: none;
}

// .company-summary-charts {
.mantine-Modal-content {
  overflow: visible !important;
}
// }

.clients-table , .goal-table{
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input {
    border: 0;
  }

  input:focus {
    border-bottom: 1px solid #aaa;
  }
  input:focus-visible {
    outline: none;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}
