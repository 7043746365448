/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */


*,
div {
  box-sizing: border-box !important;
}


.screen {
  height: 100vh;
  width: 100%;
}

.text-bold {
  font-weight: bold;
}

.text-blue {
  color: dodgerblue;
}

.report-section.table-row {
  border-bottom: 1px solid #eee;
}

.dashboard.screen.custom-report {
  display: flex;
  justify-content: space-evenly;

  .sidebar-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .sidebar {
      position: fixed;
      margin: auto;
    }

    .sidebar-item {
      cursor: pointer;
      width: 100%;
      text-align: center;
      background: rgba(0, 0, 0, 0.2);
      padding: 4px;
      border: 1px solid rgba(0, 0, 0, 0.5);
      border-radius: 300px;
      margin-bottom: 4px;
      transition: 0.3s;

      &:hover {
        filter: brightness(0.8);
      }

      &.active {
        background: coral;
        color: white;
      }
    }
  }

  .content-header {
    z-index: 11;
    background: #fff;

    .filters-section {
      display: flex;

      .filter-select {
        padding-right: 8px;
        min-width: 140px;
        display: flex;
        flex-direction: column;

        label {
          padding-bottom: 4px;
          font-size: 0.8rem;
          font-weight: bold;
        }

        select {
          padding: 4px;
        }
      }
    }
  }

  .table-row {
    display: flex;
    justify-content: space-between;
    padding: 4px 0;

    .table-col {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      padding: 0 2px;
      -webkit-box-orient: vertical;
    }

    &.table-headers {
      padding-top: 24px;
      padding-bottom: 0;

      .header-section {
        padding-bottom: 16px;
        border-bottom: 1px solid dodgerblue;

        .table-col {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          line-clamp: 3;
          padding: 0 2px;
          -webkit-box-orient: vertical;
        }
      }
    }

    .header-section {
      display: flex;

      &.data-area {
        // width: 45%;

        .table-col {
          &.title {
            min-width: 120px;
            @media (max-width:1280px) {
              min-width: 90px;
              
            }
          }

          width: 10%;
          min-width: 80px;
          @media (max-width:1280px) {
            min-width: 60px;
            
          }
        }
      }

      &.projection-area {
        margin-left: 10px;
        width: full;

        .table-col {
          // width: 16%;
          min-width:80px;
          @media (max-width:1280px) {
            min-width: 60px;
            
          }

          &:nth-last-child(1) {
            // width: 20%;
            min-width:80px;
            @media (max-width:1280px) {
              min-width: 60px;
              
            }
          }
        }
      }

      &.rate-area {
        margin-left: 10px;
        // width: 20%;

        .table-col {
          // width: 25%;
          min-width:80px;
          @media (max-width:1280px) {
            min-width: 60px;
            
          }
        }
      }

      &.manager-area {
        margin-left: 10px;
        // width: 5%;
        min-width:80px;
        justify-content: center;
      }
    }
  }

  &, & select, & input {
    font-size: 0.7rem;
    @media (max-width: 1280px){
      font-size: 0.6rem;
    }

    // @media (min-width: 2140px) and (max-width: 2400px) {
    //   font-size: .8rem;
    // }

    // @media (min-width: 1800px) and (max-width: 2140px) {
    //   font-size: .7rem;
    // }

    // @media (min-width: 1600px) and (max-width: 1800px) {
    //   font-size: .63rem;
    // }

    // @media (min-width: 1400px) and (max-width: 1600px) {
    //   font-size: .5rem;
    // }

    // @media (min-width: 1200px) and (max-width: 1400px) {
    //   font-size: 0.45rem;
    // }

    // @media (min-width: 900px) and (max-width: 1200px) {
    //   font-size: 0.4rem;
    // }

    // @media (min-width: 700px) and (max-width: 900px) {
    //   font-size: 0.25rem;
    // }


    // @media (max-width: 700px) {
    //   font-size: 0.3rem;
    // }
  }

  .sidebar-section {
    width: 10%;
  }

  .main-content {
    width: calc(100% - 140px);
    display: flex;
    flex-direction: column;
    padding: 0 16px;

    .table-area {
      flex: 1;

      .table-headers {
        position: sticky;
        top: 0;
        padding-top: 8px;
        background: white;
        z-index: 10;
      }

      .branch-section-top {
        padding: 8px 0;
        position: sticky;
        top: 42px;
        background: white;
      }
    }
  }
}


