/*
 * Copyright (C) 2023 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

$dn-background: #cddfdb;
$line-orange: #fb8e2c;
$line-green: #2ea42e;
$line-maroon: #c4a9c1;
$line-blue: #3fa4e8;
$dn-primary: #01b8aa;

.branches-section {
  .branch-menu {
    font-size: 0.7rem;
    cursor: pointer;
    padding: 6px 12px;
    margin: 3px 8px;
    background: white;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.4);
    transition: 0.3s;
    @media  (min-width: 768px) and (max-width:1280px) {
      font-size: 0.6rem;
    }
    @media  (min-width: 640px) and (max-width:768px) {
      font-size: 0.5rem;
      padding: 8px 4px;
      margin: 3px 4px;
    }
    @media  (max-width: 640px) {
      font-size: 0.4rem;
      padding: 8px 4px;
      margin: 3px 4px;

    }
    &.active,
    &.active.bg-gray {
      background: $line-green;
      color: white;
    }

    &.bg-gray {
      background: #888888;
      color: white;
    }
    &:hover {
      background: #d6d6d6;
    }
  }
}
