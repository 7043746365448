/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

$cs-background: #e2efed;
$line-orange: #fb8e2c;
$line-green: #2ea42e;
$line-maroon: #c4a9c1;
$line-blue: #3083bb;
$cs-primary: #01b8aa;

.cs-summary-screen {
  position: relative;
  min-height: 100vh;
  background: $cs-background;
  width: 100%;

  .cs-summary-section {
    height: 100%;
    width: 100%;

    .cs-sidebar-section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: sticky;
      top: 0;
      width: 150px;
      min-width: 150px;
      padding: 16px 8px;
      height: 100vh;
    

      .sidebar-item {
        cursor: pointer;
        width: 100%;
        text-align: center;
        background: rgba(255, 255, 255, 9.12);
        padding: 4px;
        border: 1px solid rgba(0, 0, 0, 0.5);
        border-radius: 300px;
        margin-bottom: 4px;
        transition: 0.3s;
        font-size: 11px;

        &:hover {
          filter: brightness(0.8);
        }

        &.active {
          background: coral;
          color: white;
        }
      }
    }

    .cs-header {
      height:300px;
      padding: 5px 20px;
      @media (max-width: 900px) {
       height:420px;
      }
      

      .cs-title-area {
        padding: 0 10vw;
      }

      .cs-filter-button {
        background: transparent;
        height: 24px;
        font-size: 11px;
        border: 1px solid black;
        margin-left: 4px;
      }

      .cs-title {
        padding: 8px 16px;
        background: $cs-primary;
        color: white;
        width: 500px;
        font-size: 12px;
        margin: 8px auto;
      }

      .branch-menu {
        &.active,
        &.active.bg-gray {
          background: $line-green;
          color: white;
        }

        &.bg-gray {
          background: #888888;
          color: white;
        }

        cursor: pointer;
        padding: 0.4rem 0.6rem;
        margin: 3px 0.4rem;
        background: white;
        font-size: 0.6rem;
        border-radius: 8px;
        border: 1px solid rgba(0, 0, 0, 0.4);
      }
    }

    .main-section {
      height: 100%;

      .cs-summary-charts {
        border-bottom: 8px solid rgba(0, 0, 0, 0.4);
      }
   
      .cs-company-charts {
        padding: 0px 14px 0px 20px;
        display: flex;
        flex-wrap: wrap;
        @media (min-width: 1024px) and (max-width: 1500px) {
            gap:6px;
          }
      

        .summary-chart-box {
          margin-bottom: 18px;
          margin-right: 5px;
          width: calc(33.33% - 6px);

          display: flex;
          align-items: flex-end;
          @media (min-width: 1024px) and (max-width: 1500px) {
            width: calc(50% - 12px);
            margin-right: 0px;
          }
          @media (max-width: 1024px) {
            width: 95%;
            margin-right: 0px;
          }

          & > div {
            width: 100%;
          }

          // &:nth-child(3n) {
          //   margin-right: 5px;
          // }

          .chart-area {
            background: white;
            border: 1px solid rgba(0, 0, 0, 0.3);
            display: flex;
            flex-direction: column;
          }

          .chart-header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 3px;

            .manager-area-summary {
              padding-left: 8px;
              font-size: 10px;

              @media (min-width: 1600px) and (max-width: 1800px) {
                font-size: 8px;
              }
            }

            .chart-header-title {
              font-size: 12px;
              font-weight: bold;
              background: $cs-primary;
              color: white;
              padding: 4px 8px;
            }
          }
        }
        .ada-style {
          width: calc(50% - 20px) !important;
        }
       
      }
    }
  }

  .branch-revenues {
    display: flex;
    gap: 3px;

    .branch-revenue-box {
      margin-top: 2px;
      font-size: 0.7rem;
      padding: 4px;
      text-align: center;
      border: 1px solid #fff;
      min-width: 30%;

      &.revenue {
        background: #eed3d1;
      }

      &.oi {
        background: #d6cad6;
      }

      &.trailing {
        background: #c3e6ee;
      } 
      &.ppm {
        background: #bae7b6;
      } 
      &.percentage {
        background: #fbead5
      }
      &.percentage2 {
        background:#d8d8da;
      }
    }
  }

  .apexcharts-legend {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .apexcharts-legend-series {
      width: 75px;
      display: flex !important;
      align-items: center;

      .apexcharts-legend-marker {
        height: 8px !important;
        width: 8px !important;
      }

      .apexcharts-legend-text {
        font-size: 8px !important;
      }
    }
  }
}

section.cs-branch-charts {
  padding: 20px 14px 20px 20px; ;
  background: $cs-background;

  .cs-branch-charts {
    display: flex;
    flex-wrap: wrap;
    @media (min-width: 1024px) and (max-width: 1500px) {
      gap:6px;
    }

    .summary-chart-box {
      margin-bottom: 18px;
      margin-right: 5px;
      display: flex;
      align-items: flex-end;
      width: calc(33.33% - 6px);

      @media (min-width: 1024px) and (max-width: 1500px) {
        width: calc(50% - 12px);
        margin-right: 0px;
      }
      @media (max-width: 1024px) {
        width: 95%;
      }

      &.total-chart {
        .w-third.manager-area {
          border: none;
        }
      }

      & > div {
        width: 100%;
      }

      // &:nth-child(3n) {
      //   margin-right: 0;
      // }

      .chart-area {
        background: white;
        border: 1px solid rgba(0, 0, 0, 0.3);
        flex-direction: column;
      }

      .chart-header {
        margin-bottom: 3px;

        .manager-revenue {
          display: flex;
          flex-direction: column;
          width: 38%;

          .manager-area-left {
            font-size: 10px;
            display: flex;
            flex-direction: column;
            padding: 2px;
            width: 100%;
          }
        }

        .name-area {
          width: 24%;
        }

        .manager-area-right {
          font-size: 10px;
          display: flex;
          flex-direction: column;
          padding: 0px;
          width: 38%;

          .w-half {
            padding: 2px 0;
          }
        }

        .chart-header-title {
          font-size: 12px;
          font-weight: bold;
          align-self: flex-end;
          background: $cs-primary;
          color: white;
          width: 100%;
          padding: 4px 8px;
          margin: 0 2px;
        }
      }
    }
    .ada-branch-style {
      width: calc(50% - 20px) !important;
      margin-right: 14px !important;

    }
  }
}
.summary-chart-box {
  margin-bottom: 18px;
  margin-right: 5px;
  display: flex;
  align-items: flex-end;
  width: calc(33.33% - 6px);
  @media (min-width: 1024px) and (max-width: 1500px) {
    width: calc(50% - 8px);
  }
  @media (max-width: 1024px) {
    width: 95%;
  }
 

  &.total-chart {
    .w-third.manager-area-summary {
      border: none;
    }
  }

  & > div {
    width: 100%;
  }

  // &:nth-child(3n) {
  //   margin-right: 0;
  // }

  .chart-area {
    background: white;
    border: 1px solid rgba(0, 0, 0, 0.3);
    flex-direction: column;
  }

  .chart-header {
    margin-bottom: 3px;

    .manager-revenue {
      display: flex;
      flex-direction: column;
      width: 38%;

      .manager-area-left {
        font-size: 10px;
        display: flex;
        flex-direction: column;
        padding: 2px;
        width: 100%;
      }
    }

    .name-area {
      width: 24%;
    }

    .manager-area-right {
      font-size: 10px;
      display: flex;
      flex-direction: column;
      padding: 0px;
      width: 38%;

      .w-half {
        padding: 2px 0;
      }
    }

    .chart-header-title {
      font-size: 12px;
      font-weight: bold;
      align-self: flex-end;
      background: $cs-primary;
      color: white;
      width: 100%;
      padding: 4px 8px;
      margin: 0 2px;
    }
  }
}

.chart-legend-area {
  font-size: 0.5rem;
  padding: 4px;
  min-width: 70px;
  display: flex;
  justify-content: space-between;

  .colors-area {
    display: flex;
    gap: 5px;

    .color-box {
      display: flex;
      align-items: center;
      margin-bottom: 2px;

      .color-circle {
        height: 8px;
        width: 8px;
        border-radius: 4px;
      }

      .color-name {
        padding-left: 2px;
      }
    }
  }

  .branch-revenues {
    display: flex;
    gap: 3px;
  }
}

.branch-legend-area {
  display: flex;
  justify-content: flex-end;
  font-size: 0.6rem;
  margin-top: 4px;

  .legend-box {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    margin-bottom: 2px;
    margin-left: 6px;

    .color-box {
      height: 20px;
      width: 30px;
      border: 1px solid #fff;

      &.revenue {
        background: #eed3d1;
      }

      &.oi {
        background: #d6cad6;
      }

      &.trailing {
        background: #c3e6ee;
      }
      &.ppm {
        background: #bae7b6
      }
      &.percentage {
        background: #fbead5
      }
      &.percentage2 {
        background:#d8d8da;
      }
    }

    .name-box {
      margin-right: 2px;
      font-size: 0.6rem;
    }
  }
}

.tooltip-toggle {
  display: flex;
  justify-content: flex-end;

  label {
    font-size: 11px;
    display: flex;
    align-items: center;
  }
}

.top-revenue {
  font-size: 1rem;
}

// .select-box {
//   right: 0;
//   top: 0;
//   position: absolute;
//   padding: 0 4px;

//   .sort-by-label {
//     text-align: right;
//     font-size: 10px;
//     font-weight: bold;
//   }

//   select {
//     font-size: 10px;
//     padding: 4px 8px;
//     border-radius: 3px;
//     width: 100%;
//   }

  .data-mode-selector-box {
    display: flex;
    font-size: 10px;
    // justify-content: flex-end;
    margin-top: 5px;

    .data-mode-selector {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 4px 8px;
      background: white;
      transition: 0.3s;
      border: 1px solid $cs-primary;

      // &:hover {
      //   filter: brightness(0.8);
      // }

      &:first-child {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
      }

      &:last-child {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
      }

      &.active {
        background: $cs-primary;
        color: white;
      }
    }

    .active-income {
      background: $cs-primary;
      color: white;
    }
  }

