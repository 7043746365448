/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

 .sidebar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  width: 150px;
  min-width: 150px;
  padding: 16px 8px;
  height: 100vh;
  @media (max-width: 640px) {
    width: 120px;
    min-width: 120px;
    padding: 14px 6px;
  }
  .sidebar-item {
    cursor: pointer;
    width: 100%;
    text-align: center;
    background: rgba(255, 255, 255, 9.12);
    padding: 4px;
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 300px;
    margin-bottom: 4px;
    transition: 0.3s;
    font-size: 11px;

    &:hover {
      filter: brightness(0.8);
    }

    &.active {
      background: coral;
      color: white;
    }
  }
}
