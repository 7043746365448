/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

.segments {
  width: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .segment-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    gap: 2%;

    .segment {
      width: 100%;
      height: 16%;
      background-color: #0f3248;
      display: flex;
      align-items: center;
      justify-content: center;

      div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #fff;
        transform: rotate(-90deg);
        font-size: 14px;
        @media (max-width:1280px) and (min-width:1024px){
          font-size: 12px;
        }
        @media (max-width:1024px){
          font-size: 9px;
        }
      }
    }
  }
}
.filters{
  height: 7%;
  @media (max-height:750px){
    height:fit-content;
  }
}

.grid-view {
  .headers {
    .grid-header {
      // padding: 10px 0;
      background-color: #008d97;
      border: 0.2px solid #00adb4;
      font-size: 12px;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
        @media (max-width:1280px) and (min-width:1024px){
          font-size: 11px;
        }
        @media (max-width:1024px){
          font-size: 9px;
        }
    }
  }

  .data-table {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    .data-title {
      display: flex;
      flex-direction: column;
      height: 50%;

      .title {
        background-color: #89c79d;
        border: 1px solid #9dbea7;
        font-size: 11px;
        height: 12.5%;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width:1024px){
          font-size: 9px;
        }
        @media (max-height:750px){
          font-size: 9px;
        }
      }
    }

    .grid-data {
      border: 4px solid #444;
      display: flex;
      align-items: center;
      height: 50%;
      width: 50%;

      .square-title-conatiner {
        width: 12%;
        height: 98%;
        border: 1px solid #4f6f7f;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        .square-title {
          transform: rotate(-90deg);
          width: 200px;
          position: absolute;
          font-weight: bold;
          font-size: 18px;
          text-align: center;
          @media (max-width:1024px){
            font-size: 12px;
          }
          @media (max-height:750px){
            font-size: 12px;
          }
        }
      }

      .client-data {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        width: 88%;
        height: 100%;

        .data {
          width: 50%;
          display: flex;
          flex-direction: column;
          height: 50%;

          span {
            height: 25%;
            border: 1px solid #ddd;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 11px;
          }
        }
      }
    }

    .growth {
      border-width: 4px 2px 2px 4px !important;
    }

    .protect {
      border-width: 4px 4px 2px 2px !important;
    }

    .review {
      border-width: 2px 2px 4px 4px !important;
    }

    .quality {
      border-width: 2px 4px 4px 2px !important;
    }
  }

  .mantine-Select-dropdown {
    background-color: #ddf0ed !important;
  }

  .segments-legend {
    display: flex;
    justify-content: flex-end;
    height: 17%;
    @media (max-height:750px){
      height:15%;
    }

    .segments-left {
      display: flex;
      width: 82%;
      justify-content: flex-end;
      padding: 10px 5px;
      gap: 10px;
      height: 100%;

      .segment {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 25%;
        border: 1px solid #444;
        // padding-top: 5px;
        justify-content: center;
      }
    }
  }
}

.table-data-container {
  flex: 1;
  margin: 5px;
  overflow: hidden;
  

  .header {
    height: 5%;
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 2px;

    div {
      // background-color: #008d97;
      width: 50%;
      // height: 80%;
      display: flex;
      flex-direction: column;
      align-items: baseline;
      @media (max-height:700px){
        flex-direction: row;
        align-items: center;
        gap:2px;
      }
    
      // justify-content: center;

      .segment-name {
        color: #1aaa9b;
        font-weight: 600;
      }
    }
  }

  .data-table {
    height: 95%;
    border: 1px solid #444;
    overflow-y: auto;
    padding: 5px;

    tbody {
      tr {
        td {
          border: 1px solid #ddd;
          padding: 2px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 0;
        }
      }
    }
  }
}
