/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Noto Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#vertical-line {
  bottom: 0;
  position: absolute;
  top: 0;
  left: 120px;
  border-right: 1px solid dodgerblue;
  z-index: 1;
  @media (max-width:1280px) {
    left:100px;
    
  }
}

::-webkit-scrollbar {
  width: 4px;
  height:4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e3e3e3;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(89, 151, 237);
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(23, 86, 174);
}

/*backgrounds*/
.bg-white {
  background: white;
}

/* flex */
.flex {
  display: flex;
}

.flex-grow {
  flex-grow: 1;
}

.justify-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: flex-end;
}

.justify-end {
  justify-content: flex-end;
}

.wrap {
  flex-wrap: wrap;
}

/*widths and heights*/
.h-full {
  height: 100%;
}

.w-full {
  width: 100%;
}

.w-third {
  width: 33.333333%;
}

.w-qtr {
  width: 25%;
}

/*paddings*/
.pl-sm {
  padding-left: 8px !important;
}

.pl-md {
  padding-left: 16px !important;
}

.pl-lg {
  padding-left: 24px !important;
}

/*typography*/
.text-white {
  color: white;
}

/*positions*/
.sticky-top {
  position: sticky;
  top: 0;
}

.pointer {
  cursor: pointer;
}

.relative {
  position: relative;
}

.text-gray {
  color: gray;
}

.text-green {
  color: green;
}

.text-red {
  color: red;
}

.bold {
  font-weight: bold;
}

.bg-gray {
  background: rgba(0, 0, 0, 0.08);
}

.pr-xs {
  padding-right: 4px !important;
}

.apexcharts-tooltip.apexcharts-theme-light {
  z-index: 9999999;
}

.actionIcons {
  padding: 0 0.125rem;
  border-radius: 0.125rem;
  color: #6b7280;
  font-size: 0.9rem;
  cursor: pointer;
  background-color: transparent;
  display: grid;
  justify-content: center;
  align-items: center;
}

.actionIcons:hover {
  color: #4b5563;
  background-color: #ffffff;
}