/*
 * Copyright (C) 2023 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

section.landing-screen{
  background: #CEE0DD;
  height:100vh;
  width: 100%;
  display: flex;
  .logo-area,.menu-area{
    height:100%;
    width:50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .menu-items{
    width: 80%;
    .landing-menu{
      display: block;
      padding: 8px 16px;
      border:2px solid rgba(0,0,0,0.3);
      border-radius: 300px;
      margin:4px 0;
      text-decoration: none;
      color:rgba(0,0,0,0.98);
      text-align: center;
      width: 80%;
      transition: .3s;
      &:hover{
        background: #00325D;
        color:white;
      }
    }
  }
}